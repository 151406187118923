<template>
    <m-main-layout>
        <el-button size="small" type="primary" slot="right-btn" @click="$router.push({path:'/rule/path'})">规则列表
        </el-button>
        <div class="page-content-main">
            <el-form :model="formData" status-icon :rules="rules" ref="form" label-width="auto" class="margin-top"
                     style="width:600px;">
                <el-input v-model="formData.id" type="hidden"></el-input>
                <el-input v-model="formData.pid" type="hidden"></el-input>
                <el-form-item label="标题" prop="title">
                    <el-input v-model="formData.title" maxlength="20" show-word-limit style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="规则路径" prop="path">
                    <el-input v-model="formData.path" maxlength="50" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="图标" prop="icon" v-if="formData.pid === 0">
                    <span class="form-icon margin-right" v-show="formData.icon">
                        <i :class="formData.icon"></i>
                    </span>
                    <el-button type="primary" @click="dialogVisible = true" native-type="button">选择图标</el-button>
                </el-form-item>
                <el-form-item label="类别" prop="type">
                    <el-radio-group v-model="formData.type">
                        <el-radio label="page">页面</el-radio>
                        <el-radio label="button">按钮</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="formData.sort" maxlength="20" show-word-limit style="width:300px;"></el-input>
                </el-form-item>
                <el-form-item label="是否可用" prop="status">
                    <el-switch
                        v-model="formData.status"
                        active-color="#13ce66"
                        active-value="1"
                        inactive-value="0"
                        active-text="启用"
                        inactive-text="禁用">
                    </el-switch>
                </el-form-item>
                <el-form-item label="系统内置路径" prop="is_system">
                    <el-switch
                        v-model="formData.is_system"
                        active-color="#13ce66"
                        active-value="1"
                        inactive-value="0"
                        active-text="启用"
                        inactive-text="禁用">
                    </el-switch>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('form')">确定</el-button>
                    <el-button type="" @click="$router.back()">返回</el-button>
                </el-form-item>
            </el-form>
        </div>
        <icon-dialog :visible="dialogVisible" @checkIcon="checkIcon" @onClose="onClose"></icon-dialog>
    </m-main-layout>
</template>

<script>
export default {
    name: "add",
    data() {
        return {
            dialogVisible: false,
            formData: {
                id: '',
                path: '',
                title: '',
                status: '1',
                pid: 0,
                icon: '',
                type: 'page',
                is_system: '1',
                sort: '1',
            },
            rules: {
                path: [
                    {required: true, message: '规则路径', trigger: 'blur'},
                    {min: 1, max: 50, message: '请输入1-50位字符', trigger: 'blur'}
                ],
                title: [
                    {required: true, message: '标题', trigger: 'blur'},
                    {min: 1, max: 20, message: '请输入1-20位字符', trigger: 'blur'}
                ],
                type: [
                    {required: true, message: '类别', trigger: 'blur'}
                ],
                status: [
                    {required: true, message: '状态必选', trigger: 'blur'},
                ],
                sort: [
                    {required: true, message: '排序', trigger: 'blur'},
                ],
            },
        };
    },
    created() {
        this.formData.id = this.$route.query.id ? this.$route.query.id : 0;
    },
    mounted() {
        if (this.formData.id !== 0) {
            this.getRuleDetail();
        }
    },
    methods: {
        submitForm(formName) {
            let _this = this;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    console.log(_this.formData);
                    _this.$api.ruleEdit(_this.formData,
                        function (res) {
                            // console.log(res);
                            _this.$router.replace({path: '/rule/path'})
                        })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        getRuleDetail() {
            let _this = this;
            console.log(_this.formData.id);
            this.$api.ruleDetail({id: _this.formData.id},
                function (res) {
                    console.log(res);
                    _this.formData.id = res.id;
                    _this.formData.path = res.path;
                    _this.formData.title = res.title;
                    _this.formData.pid = res.pid;
                    _this.formData.sort = res.sort;
                    _this.formData.type = res.type;
                    _this.formData.icon = res.icon;
                    _this.formData.status = res.status.toString();
                    _this.formData.is_system = res.is_system.toString();
                })
        },
        checkIcon(icon) {
            this.formData.icon = icon;
            this.dialogVisible = false;
        },
        onClose() {
            this.dialogVisible = false;
        }
    }
}
</script>
